const content = {
  title: 'Entgeltumwandlung/Laufende Beiträge',
  headline: 'Entgeltumwandlung/ Laufende Beiträge',
  topTextSection: {
    headline: 'Heute Geschäftsführer - später Rentenprobleme.',
    content:
      'Spezieller Schutz für Vorstandsmitglieder, Geschäftsführer und leitende Angestellte. Geschäftsführer, leitende Angestellte und Vorstände haben einiges gemeinsam: Das Engagement ist hoch, die berufliche Belastung stark und der Lebensstandard gehoben. Doch die Altersvorsorge hinkt oft noch hinterher. Sie muss ebenfalls den späteren Ansprüchen angepasst werden. Aus diesem Grund müssen Sie ihre Altersvorsorge aktiv gestalten. Tun sie es nicht, stehen sie in der Rentenphase vor einem großen Problem.'
  },
  altersversorungSection: {
    headline:
      'Deshalb ist eine betriebliche Altersversorgung für Sie als Gesellschafter so wichtig.',
    cardContent:
      'Gerade bei Gesellschaftern und sonstigen Führungskräften, die nicht rentenversicherungspflichtig sind, ist die Versorgungslücke im Alter besonders groß.',
    buttonText: 'Mehr anzeigen',
    buttonVariant: 'gray-blue',
    dialogTitle: 'Betriebliche Altersversorgung für Gesellschafter',
    dialogParagraphs: [
      'Geschäftsführer werden in der Regel mit Beginn der unternehmerischen Tätigkeit von der Sozialversicherung befreit. Das heißt: Sind Ihre bislang erworbenen gesetzlichen Anwartschaften zum geplanten Rentenbeginn gering oder sogar nicht vorhanden, tut sich eine Versorgungslücke auf.',
      'Falls Sie es versäumen, eine adäquate private und betriebliche Absicherung aufzubauen, können Sie in eine kritische Situation geraten, die sogar existenzbedrohend sein kann.',
      'Aber auch bei leitenden Angestellten und Vorständen, die oberhalb der Beitragsbemessungsgrenze liegen kann sich eine Rentenlücke ergeben.'
    ]
  },
  direktversichungsSection: {
    headline: 'Entgeltumwandlung über die Direktversicherung',
    paragraphs: [
      'Damit die Leistungen der betrieblichen Altersversorgung steuerlich anerkannt werden, setzen die Finanzbehörden bei den Versorgungszusagen für GGFs einiges voraus. Die Direktversicherung ist der ideale Einstieg in die Chefversorgung, bildet somit die <strong>erste Stufe</strong> und bietet vielfältige tarifliche Möglichkeiten. Sie ist besonders für kleine und mittlere Unternehmen geeignet, da für Sie als Arbeitgeber der Verwaltungsaufwand gering ist und Sie kein finanzielles Risiko tragen.',
      'Da gerade bei beherrschenden Geschäftsführern die Finanzbehörde einiges voraussetzt, bildet die Entgeltumwandlung über eine Direktversicherung eine gute Basis für eine gute Altersvorsorge. Aber auch leitende Angestellte und Vorstände sollten die steuerlichen Vorteile, die Ihnen der Stadt gewährt nutzen.',
      'In der Praxis wird hier vom Arbeitgeber für Sie als Arbeitnehmer eine Rentenversicherung abgeschlossen. Bei diesem Vertrag sind Beiträge bis zu 8 % der Beitragsbemessungsgrenze (West) der Gesetzlichen Rentenversicherung steuerfrei. Sozialversicherungsfreiheit besteht für Beiträge bis zu 4 % der Beitragsbemessungsgrenze.'
    ]
  },
  unterstuetzungskasseSection: {
    leftCard: {
      topline: 'Unterstützungskasse als zweite Stufe',
      headline: 'Laufende Beiträge über die Unterstützungskasse',
      paragraphs: [
        'Als <strong>zweite Stufe</strong> empfiehlt sich die Unterstützungskasse, da durch hohe laufende Beiträge ein für die Stellung eines Gesellschafter-Geschäftsführers, leitenden Angestellten bzw. Vorstands entsprechendes Rentenniveau erreicht werden kann.',
        'Die Unterstützungskasse ist besonders geeignet für Unternehmen, die eine bAV begrüßen, jedoch keine bilanziellen Auswirkungen wünschen. Das ist aus dem Grund der Fall, da die Unterstützungskasse die Versorgungszusage erteilt. Somit müssen Sie als Arbeitgeber keine Rückstellungen bilden und die Versorgung über eine rückgedeckte Unterstützungskasse ist bilanzneutral.'
      ]
    },
    rightCard: {
      headline: 'Weitere Vorteile der Unterstützungskasse sind u.a.:',
      listItems: [
        'Die individuelle Gestaltung der Altersvorsorge (sowohl Renten- als auch Kapitalzusagen möglich)',
        'Steuerlich attraktive Fünftelregelung kann genutzt werden.',
        'Vergünstigte Gruppenkonditionen können bereits ab einer versicherten Person genutzt werden'
      ]
    }
  },
  bonusVorsorgeSection: {
    topline: 'Bonus-Vorsorge-Flex',
    headline: 'Pensionszusage für Einmalbeiträge',
    paragraphs: [
      'Im unternehmerischen Umfeld gibt es aber nicht nur Zahlungen, die laufend erbracht werden. Jährliche Tantieme z. B. laden dazu ein, einmal jährlich etwas für die Altersvorsorge zu tun. Doch in die Direktversicherung sind Einmalzahlungen nur begrenzt möglich und in der Unterstützungskasse sind sie steuerlich sogar gar nicht vorgesehen. Somit sollte als <strong>dritte Stufe</strong> die Variante der Bonus-Vorsorge-Flex gewählt werden.',
      'Hierbei wird von Ihnen zugunsten einer beitragsorientierten Leistungszusage auf Gehaltsbestandteile wie z. B. Tantiemen verzichtet. Die Einmalzahlung ist dabei in unbegrenzter Höhe steuerfrei und da es sich um eine beitragsorientierte Leistungszusage mit einer äquivalenten Rückdeckungsversicherung handelt, haben Sie kaum ein Haftungsrisiko. Der große Vorteil gegenüber klassischen Pensionszusagen.',
      'Zudem ist dieses Konzept, das bei der NÜRNBERGER <i><strong>„Bonus-Vorsorge-Flex“</strong></i> genannt wird, bilanzneutral.'
    ]
  },
  betriebsrenteSection: {
    leftCard: {
      headline: 'Ihre Vorteile mit einer Betriebsrente',
      listItems: [
        'Nutzung positiver Steuereffekte für das Unternehmen möglich',
        'Geringer Verwaltungsaufwand: Wir übernehmen die Verwaltung für Sie',
        'Ihre Betriebsrente ist auch im Fall einer Insolvenz geschützt.',
        'Beratung durch unsere Spezialisten vor Ort',
        'Beiträge sind bei der beitragsorientierten Pensionszusage i. d. R. steuerfrei, erst die spätere Rente muss versteuert werden'
      ]
    },
    rightCard: {
      headline: 'BU-Schutz nicht vergessen',
      content:
        'Gerade wenn Sie sich Gedanken über die Möglichkeiten einer betrieblichen Absicherung und Vorsorge machen, ist es für Sie als Unternehmer wichtig daran zu denken, wie es weiter geht, wenn Sie einmal berufsunfähig werden. Und das geht schneller als Sie vielleicht glauben, denn z. B. auch eine Krebserkrankung kann dafür sorgen, dass Sie zweitweise ausfallen.',
      buttonText: 'Mehr anzeigen',
      buttonVariant: 'ghost',
      dialogTitle: 'BU-Schutz',
      dialogParagraphs: [
        'Wie gut, wenn Sie daran gedacht haben in ihre Betriebsrente einen Berufsunfähigkeitsschutz zu integrieren. Sie erhalten dann Ihrem Lohne von der Betriebsrente und belasten ihre Firma somit nicht. Es besteht somit der finanzielle Spielraum z. B. einen Geschäftsführer befristet einzustellen oder andere Maßnahmen zu ergreifen.',
        'Um keine Renteneinbußen hinzunehmen empfiehlt sich zudem, in der Vorsorge zu vereinbaren, dass die Beiträge vom Versicherer weitergezahlt werden, wenn sie es nicht können.'
      ]
    }
  },
  bavGesellschafterSection: {
    topline: 'Direktversicherung kombiniert mit Unterstützungskasse.',
    headline: 'Wie die bAV für Sie als Gesellschafter aussehen könnte.',
    content: [
      '<strong>Die Grundlage:</strong> Für den Geschäftsführer Kröger wird wie für einen Mitarbeiter eine Direktversicherung vereinbart. Doch wegen der Begrenzung der steuerlich geförderten Aufwendungen auf jährlich 6.768 EUR (in 2022) reichen die Leistungen nicht aus, um den vollen Versorgungsbedarf zu decken. Die rückgedeckte Unterstützungskasse rundet die Versorgung deshalb ab: Dort kann Herr Kröger seinem Versorgungsbedarf entsprechend höhere Beiträge einzahlen. Steuerfrei für ihn selbst, abzugswirksam für das Unternehmen. Im März erhält Herr Kröger eine Tantieme in Höhe von 10.000 EUR. Um diese nicht mit dem Höchststeuersatz versteuern zu müssen, wandelt er diese im Rahmen einer BonusVorsorge flex in bAV um.'
    ]
  },
  chefSection: {
    headline:
      'Was Sie als Chef noch interessieren könnte: Pensionsverpflichtungen auslagern',
    content:
      'Pensionszusagen sind häufig ein Relikt aus der Vergangenheit. Wurden Sie früher als Steuersparmodell beworben, werden Sie heute als unkalkulierbares Risiko kritisch gesehen.',
    buttonText: 'Mehr anzeigen',
    buttonVariant: 'gray-blue',
    dialogTitle: 'Pensionsverpflichtungen auslagern',
    dialogParagraph:
      'Mögliche Auswirkungen können dabei Probleme beim Beschaffen von Fremdkapital oder gar die Insolvenz des Unternehmens sein. Deswegen sollte schon frühzeitig an die Sanierung bzw. Auslagerung der Pensionszusage gedacht werden. Die NÜRNBERGER bietet hier interessante und hilfreiche Optionen, die Last aus Ihrer Bilanz zu nehmen.'
  },
  fragenSection: {
    headline: 'Häufige Fragen zur Absicherung für leitende Angestellte / GGF',
    accordionItems: [
      {
        title:
          'Eignet sich die bAV für Geschäftsführer oder Inhaber aller Firmen?',
        paragraphs: [
          'Betriebliche Altersversorgung und damit verbunden die steuerlichen Vorteile hängen in erster Linie von einem bestehenden Arbeitsverhältnis ab. Dieses ist selbstverständlich bei einem angestellten Prokuristen, Geschäftsführer oder auch beherrschenden Gesellschafter-Geschäftsführer einer GmbH gegeben. Nicht jedoch z. B. beim Inhaber einer Einzelfirma.'
        ]
      },
      {
        title: 'Wie sollte die optimale Versorgung aussehen?',
        paragraphs: [
          'So vielfältig wie das Leben, so vielfältig kann auch die betriebliche Altersversorgung sein. Eine Standardlösung gibt es nicht. Vielmehr hängt die Ausgestaltung von betriebsindividuellen Faktoren ab. Bei der Frage, welche Lösung am besten zu Ihrem Unternehmen passt, hilft Ihnen gerne Ihr Versicherungsberater.'
        ]
      },
      {
        title:
          'Gibt es bei der Absicherung von Geschäftsführern Besonderheiten?',
        paragraphs: [
          'Grundsätzlich funktioniert die sogenannte Chefversorgung genauso wie die Versorgung der Mitarbeiter. Jedoch gibt es insbesondere bei gesellschaftsnahen Mitarbeitern (z. B. beteiligten Geschäftsführern) einige steuerliche Anforderungen, die bei der Einrichtung der bAV unbedingt beachtet werden sollten. Wir empfehlen Ihnen hier die Unterstützung eines versierten steuerlichen Beraters.'
        ]
      }
    ]
  }
};

export default content;
