import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import {
  SectionHead,
  HalvedContent,
  ContentBox,
  List,
  Accordion,
  Dialog,
  Paragraphs
} from 'src/components';

import content from 'src/content/unsere-loesungen/absicherung-fuer-leitende-angestellte/entgeltumwandlung-laufende-beitraege';

const EntgeltumwandlungLaufendeBeitraegePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      position: '75',
      headline: content.headline
    }}
  >
    <section>
      <SectionHead headline={content.topTextSection.headline}>
        <p>{content.topTextSection.content}</p>
      </SectionHead>
      <HalvedContent align="center">
        <BackgroundImage fluid={data.oldManWithWifeOutside.childImageSharp.fluid} />
        <ContentBox
          background="light-salmon"
          headline={content.altersversorungSection.headline}
        >
          <p>{content.altersversorungSection.cardContent}</p>
          <Dialog
            buttonText={content.altersversorungSection.buttonText}
            buttonVariant={content.altersversorungSection.buttonVariant}
            title={content.altersversorungSection.dialogTitle}
          >
            <Paragraphs
              items={content.altersversorungSection.dialogParagraphs}
            />
          </Dialog>
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="direktversicherung">
      <HalvedContent align="left">
        <BackgroundImage
          fluid={data.WomanWithTabletSittingOnOfficeDesk.childImageSharp.fluid}
          className={"bg-75"}
        />
        <ContentBox headline={content.direktversichungsSection.headline} wider>
          <Paragraphs
            items={content.direktversichungsSection.paragraphs}
            innerHTML
          />
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="unterstuetzungskasse">
      <HalvedContent align="right">
        <ContentBox
          topline={content.unterstuetzungskasseSection.leftCard.topline}
          headline={content.unterstuetzungskasseSection.leftCard.headline}
          wider
        >
          <Paragraphs
            items={content.unterstuetzungskasseSection.leftCard.paragraphs}
            innerHTML
          />
        </ContentBox>
        <ContentBox
          background="light-blue"
          headline={content.unterstuetzungskasseSection.rightCard.headline}
        >
          <List
            items={content.unterstuetzungskasseSection.rightCard.listItems}
          />
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="einmalbeitraege">
      <HalvedContent align="left">
        <BackgroundImage
          fluid={data.womanWithGlasesInHandLaughing.childImageSharp.fluid}
          className={"bg-75"}
        />
        <ContentBox
          topline={content.bonusVorsorgeSection.topline}
          headline={content.bonusVorsorgeSection.headline}
          wider
        >
          <Paragraphs
            items={content.bonusVorsorgeSection.paragraphs}
            innerHTML
          />
        </ContentBox>
      </HalvedContent>
    </section>
    <section>
      <HalvedContent>
        <ContentBox
          background="light-blue"
          headline={content.betriebsrenteSection.leftCard.headline}
          align="right"
        >
          <List items={content.betriebsrenteSection.leftCard.listItems} />
        </ContentBox>
        <ContentBox
          background="dark-blue"
          headline={content.betriebsrenteSection.rightCard.headline}
          align="left"
        >
          <p>{content.betriebsrenteSection.rightCard.content}</p>
          <Dialog
            buttonText={content.betriebsrenteSection.rightCard.buttonText}
            buttonVariant={content.betriebsrenteSection.rightCard.buttonVariant}
            title={content.betriebsrenteSection.rightCard.dialogTitle}
          >
            <Paragraphs
              items={content.betriebsrenteSection.rightCard.dialogParagraphs}
            />
          </Dialog>
        </ContentBox>
      </HalvedContent>
    </section>
    <section id="leistungszusage">
      <HalvedContent align="right">
        <ContentBox
          topline={content.bavGesellschafterSection.topline}
          headline={content.bavGesellschafterSection.headline}
          wider
        >
          <p
            dangerouslySetInnerHTML={{
              __html: content.bavGesellschafterSection.content
            }}
          />
        </ContentBox>
        <BackgroundImage
          fluid={data.manInWhiteShirtLaughing.childImageSharp.fluid}
        />
      </HalvedContent>
    </section>
    <section className="bg-light-salmon">
      <div className="container container--simple children-margin">
        <h3>{content.chefSection.headline}</h3>
        <p>{content.chefSection.content}</p>
        <Dialog
          buttonText={content.chefSection.buttonText}
          buttonVariant={content.chefSection.buttonVariant}
          title={content.chefSection.dialogTitle}
        >
          <p>{content.chefSection.dialogParagraph}</p>
        </Dialog>
      </div>
    </section>
    <section>
      <div className="container container--simple">
        <h3 className="mb-5">{content.fragenSection.headline}</h3>
        {content.fragenSection.accordionItems.map((accordionItem, index) => (
          <Accordion key={index} title={accordionItem.title}>
            <Paragraphs items={accordionItem.paragraphs} />
          </Accordion>
        ))}
      </div>
    </section>
  </MainLayout>
);

export default EntgeltumwandlungLaufendeBeitraegePage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-laptop-and-papers-wide.jpg" }
    ) {
      ...image
    }
    oldManWithWifeOutside: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "old-man-with-wife-outside.jpg" }
    ) {
      ...image
    }
    WomanWithTabletSittingOnOfficeDesk: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-tablet-sitting-on-office-desk.jpg" }
    ) {
      ...image
    }
    womanWithGlasesInHandLaughing: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-glases-in-hand-laughing.jpg" }
    ) {
      ...image
    }
    manInWhiteShirtLaughing: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-in-white-shirt-laughing.jpg" }
    ) {
      ...image
    }
  }
`;
